import React from 'react';
import './../assets/style/Footer.scss';
import Container from 'reactor/components/Grid/ContainerWithWidth';
import GridItem from 'reactor/components/Grid/GridItem';
import BoxItem from 'reactor/components/Box';
import ps from 'assets/images/technologies/ps.png';
import xd from 'assets/images/technologies/xd.png';
import mongodb from 'assets/images/technologies/mongodb.png';
import react from 'assets/images/technologies/react.png';
import laravel from 'assets/images/technologies/laravel-1.png';
import php from 'assets/images/technologies/php-1.png';
import html5 from 'assets/images/technologies/html5-2.png';
import mysql from 'assets/images/technologies/mysql-3.png';
import VAT from 'assets/images/VAT-Img.png'
import qr from 'assets/images/qr.png'
import Social from './SocialLinks'
import HelpSection from './../home/components/help/HelpSection'
import Whatsapp from './Whatsapp';
import FixedContactButton from './FixedContactBtn';

const FooterLinksHelp = [
    {
        title: 'كيف أقوم بفتح تذكرة؟',
        href: ''
    },
    {
        title: 'خدمة ما بعد البيع',
        href: ''
    },
    {
        title: 'الرسائل التنبيهية',
        href: ''
    },
    {
        title: 'العروض الترويجية',
        href: ''
    }
];

const FooterLinks = [
    {
        title: 'المدونة',
        href: '/blog'
    },
    {
        title: 'تصميم المواقع الإلكترونية',
        href: '/webdesign'
    },
    {
        title: 'خدمات الدعم الفني',
        href: '/technicalsupport'
    },
    {
        title: 'تصميم الأكاديميات الالكترونية',
        href: ''
    },
    {
        title: "اتفاقية الخدمة",
        href: "/rowaad-tos"
    },
    {
        title: "تصميم المتاجر الإلكترونية",
        href: "/stores"
    },
    {
        title: 'استضافة المواقع',
        href: ''
    },
    {
        title: 'خدمات التسويق الالكتروني',
        href: '/marketing'
    },
    {
        title: 'سياسة الخصوصية',
        href: '/rowaad-privacy'
    },
    {
        title: 'تصميم تطبيقات الجوال',
        href: '/apps'
    },
    {
        title: 'خوادم مشتركة',
        href: ''
    },
    {
        title: 'اتصل بنا',
        href: '/contact-us'
    },
];

const rowaadTech = [
    {
        img: ps,
        title: "أدوبي فوتوشوب"
    },
    {
        img: xd,
        title: "أدوبي إكس دي"
    },
    {
        img: mongodb,
        title: "مونغو دي بي"
    },
    {
        img: react,
        title: "ريأكت"
    },
    {
        img: laravel,
        title: "لارافيل"
    },
    {
        img: php,
        title: "بي إتش بي."
    },
    {
        img: html5,
        title: "إتش تي إم إل 5"
    },
    {
        img: mysql,
        title: "ماي إس كيو إل"
    },
];

export default function Footer() {
    return (
       <>
        <HelpSection />
        <footer>
            <Container>
                {/* <GridItem xs={12}>
                    <h3 className="footer__title">روابط</h3>
                    <ul className="list-unstyled footer__links">
                        { FooterLinks.map( (link, index) => (
                            <li key={index}>
                                <Link className="menuLink" href={link.href}>{link.title}
                                    <BoxItem component="span" display="block">{link.subtitle}</BoxItem>
                                </Link>
                            </li>
                        )) }
                    </ul>
                </GridItem> */}
                <GridItem xs={12}>
                    <BoxItem className="rowaad__items" display="flex" justify="space-between" alignItems="center">
                        <div className="rowaad__item rowaad__item--tech">
                            <h2>
                                <span>التقنيات</span>
                                المستخدمة.
                            </h2>
                        </div>
                        <div className='technologies'>
                            {rowaadTech.map( (rowaadItem, index) => (
                                <div className="rowaad__tech" key={index}>
                                    <img loading="lazy" src={rowaadItem.img} alt={rowaadItem.title} />
                                </div>
                            ))}
                        </div>
                    </BoxItem>
                </GridItem>
                <GridItem sm={6} xs={12}>
                <div className="footer__rights my-5">
                        <p>جميع الحقوق محفوظة لـ <span>شركة رواد الاستضافة لتقنية المعلومات</span></p>
                        <p>الموقع يعمل على الاجهزة الكفية بدقة عالية</p>
                    </div>
                    <Social />
                </GridItem>
                <GridItem sm={6} xs={12}>
                    <div className="footer__rowaad">
                        <p>
                            رواد
                            {/* <img src={Heart} alt="Rowaad" /> */}
                            <span className="rowaad_heart"></span>
                            شريك نجاح
                        </p>
                    </div>
                    <div className="rowaad__images" >
                        <a aria-label="ضريبة القيمة المضافة" href="https://rh.net.sa/VAT-Registration-Certificate.PDF" target="_blank" >
                            <img  loading="lazy" src={VAT} alt="ضريبة القيمة المضافة" />
                        </a>
                        <a aria-label="شركة رواد الاستضافة لتقنية المعلومات" href="https://rh.net.sa/Papers-RH.pdf" target="_blank">
                            <img  loading="lazy" src={qr} alt="شركة رواد الاستضافة لتقنية المعلومات" />
                        </a>
                    </div>
                </GridItem>
            </Container>
        </footer>
        <div className='fixed-btns'>
            <Whatsapp/>
            <FixedContactButton/>
        </div>
       </>
    )
}